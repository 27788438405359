import React, { useState } from 'react';
import { Button, ErrorSummary } from 'govuk-react-jsx';
import { Input } from 'govuk-react-jsx/govuk/components/input';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkValidEmail } from '../regex/regex';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';
import { setAccountEmail } from '../features/registration/registrationJourneySlice';
import { resetPasswordGeneral } from '../firebase';
import useTitle from '../hooks/useTitle';

const PasswordRequestLink = () => {
  const [errorList, setErrorList] = useState([]);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'SMEReg.PasswordRequestLink.s6',
    'Request a link to reset your password - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  // Error messages
  const blankEmailErrorMsg = {
    children: copyProvider.getCopy(
      'SMEReg.PasswordRequestLink.s2',
      'Enter your email address'
    ),
  };
  const invalidEmailErrorMsg = {
    children: copyProvider.getCopy(
      'SMEReg.PasswordRequestLink.s5',
      'Enter a valid email address'
    ),
  };
  const noAccountFoundErrorMsg = {
    children: copyProvider.getCopy(
      'SMEReg.PasswordRequestLink.s7',
      'Unable to find account details to match.'
    ),
  };

  // Handle button function
  const handleButtonClick = () => {
    const errorListArr = [];
    if (email === '') {
      setErrorEmail(blankEmailErrorMsg);
      errorListArr.push({ ...blankEmailErrorMsg, href: '#email' });
      setErrorList(errorListArr);
    } else if (!checkValidEmail.test(email)) {
      setErrorEmail(invalidEmailErrorMsg);
      errorListArr.push({ ...invalidEmailErrorMsg, href: '#email' });
      setErrorEmail(invalidEmailErrorMsg);
    } else {
      resetPasswordGeneral({ email })
        .then(() => {
          dispatch(setAccountEmail(email));
          history.push('/confirm-link-sent');
        })
        .catch((error) => {
          if (error.message === 'No account found with the provided email.') {
            setErrorEmail(noAccountFoundErrorMsg);
            errorListArr.push({ ...noAccountFoundErrorMsg, href: '#email' });
            setErrorList(errorListArr);
          }
        });
    }
  };

  // Handle input field
  const handleEmail = (event) => {
    setEmail(event.target.value.trim().toLowerCase());
  };
  const pageModel = (
    <div>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'SMEReg.PasswordRequestLink.s1',
          'Request a link to reset your password'
        )}
      </h1>
      <p className="govuk-body-m">
        {copyProvider.getCopy(
          'SMEReg.PasswordRequestLink.s2',
          'Enter your email address'
        )}
      </p>
      <Input
        errorMessage={errorEmail || null}
        className="govuk-input--width-20"
        hint={{
          children: copyProvider.getCopy(
            'SMEReg.PasswordRequestLink.s3',
            'We will send a link to this email to reset your password'
          ),
        }}
        id="email"
        name="email"
        type="email"
        value={email}
        onChange={handleEmail}
      />
      <Button onClick={handleButtonClick}>
        {copyProvider.getCopy('SMEReg.PasswordRequestLink.s4', 'Send a link')}
      </Button>
    </div>
  );
  return pageModel;
};

export default PasswordRequestLink;
